jQuery.fn.isInViewport = function (offset) {
    var elementTop = jQuery(this).offset().top;
    var elementBottom = elementTop + jQuery(this).outerHeight();
    var viewportTop = jQuery(window).scrollTop();
    var viewportBottom = viewportTop + jQuery(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom - jQuery(this).height()+offset;
};
function posPage(){
    var posNav =0;
    if(jQuery('.nav-second').length){
        posNav=(jQuery('.nav-second').height());
    }
    var scrollPos = jQuery(this).scrollTop()+posNav+150;
    /*if(window.innerWidth<768){
     scrollPos = jQuery(this).scrollTop();
     }*/
    var pageHei = jQuery('.scroll-sect:nth-of-type(1)').height();
    var curent=0;
    jQuery('.scroll-sect').each(function(){

        var thisInd = curent;
        jQuery(this).attr('data-index',thisInd );
        curent++;
        var pagePos = jQuery(this).offset().top;
        pageHei = jQuery(this).height();
        //console.log(thisInd);
        if(scrollPos >= (pagePos) && scrollPos < (pagePos + pageHei)){
            jQuery('.nav-second li, .left-nav>li').removeClass('active').eq(jQuery(this).attr('data-index')).addClass('active');
            jQuery('.dropdown-style .wrap-drop-down .toggle-open span').text(jQuery('.nav-second li, .left-nav>li').eq(jQuery(this).attr('data-index')).find('a').text())
        }

    });
}

//menu click function//
jQuery(document).ready(function($) {
    $('.sub-menu .main-menu-block .nav-column>ul>li>ul>li>a').each(function () {
        $(this).click(function () {
        $(this).parent().children("ul").slideToggle();
        $(this).children("i").toggleClass('closed');
    }); 
   });
   

});

//marterial filter function//
jQuery(document).ready(function($) {

    $('.radio-filter-header').click(function () {
        $(this).parent().children(".radio-filter").slideToggle();
        $(this).children("i").toggleClass('closed');
    }); 
   
    $('.radio-filter input[type=radio]').click(function(){
        if (this.previous) {
            this.checked = false;
        }
        this.previous = this.checked;
        if($('.radio-filter input:radio').is(':checked')) { 
            $(".materiality-assessment-wrap-map").addClass("filtered")
        }else{
            $(".materiality-assessment-wrap-map").removeClass("filtered")
        }
    });

    const filter1 =null;
    const filter2 =null;
    const filter3 =null;
    $(".radio-filter input:radio").each(function(){
    $(this).click(function(){

    if ($('[name="value-chain"]').is(':checked')){

    var filter1 = "." + $("[name='value-chain']:checked").val();
        }else{var filter1 = ""}
            if ($('[name="time-horizon"]').is(':checked')){

    var filter2 = "." + $("[name='time-horizon']:checked").val();
                }else{var filter2 = ""}
                if ($('[name="material-priority"]').is(':checked')){

    var filter3 = "." + $("[name='material-priority']:checked").val();
                }else{var filter3 = ""}

    var filterresult = filter1 + filter2 + filter3;
    filterresult = filterresult.replace(/ /g, '');
    
     if (filterresult == ""){

     $(".reset-map-assessment").click();
     }else{
        $('path,.toggle-materiality-assessment').addClass('not-active').removeClass('active').removeClass('section_active').attr('data-active', '');
        $('.item-tabs-content-materiality').removeClass('active');
        $('.indicator').removeClass('active').addClass('not-active');
     $(filterresult).addClass('active').removeClass('not-active');
     $(filterresult).attr("data-active",filterresult);
         
     //Combination Condition//
    //	 Financially material priority	 Upstream		.filter1-1.filter3-1
    $('#middle-target6[data-active=".filter1-1.filter3-1"],#left-target9[data-active=".filter1-1.filter3-1"]').removeClass("active").addClass("not-active");

    //	 Financially material priority	 Own operations		.filter1-2.filter3-1
    $('#right-target3[data-active=".filter1-2.filter3-1"]').removeClass("active").addClass("not-active");
    //	 Financially material priority	 Downstream		.filter1-3.filter3-1
    $('#left-target9[data-active=".filter1-3.filter3-1"]').removeClass("active").addClass("not-active");
    //	 Impact material priority	 Upstream		.filter1-1.filter3-2
    $('#left-target2[data-active=".filter1-1.filter3-2"],#path3[data-active=".filter1-1.filter3-2"],#middle-target1[data-active=".filter1-1.filter3-2"],#middle-target3[data-active=".filter1-1.filter3-2"]').removeClass("active").addClass("not-active");
    //	 Impact material priority	 Own operations		.filter1-2.filter3-2
    $('#left-target9[data-active=".filter1-2.filter3-2"]').removeClass("active").addClass("not-active");
    //	 Impact material priority	 Downstream		.filter1-3.filter3-2
    $('#middle-target1[data-active=".filter1-3.filter3-2"],#right-target3[data-active=".filter1-3.filter3-2"]').removeClass("active").addClass("not-active");
    
    //	 Financially material priority	 Short-term		.filter2-1.filter3-1
    $('#left-target9[data-active=".filter2-1.filter3-1"]').removeClass("active").addClass("not-active");
    //	 Financially material priority	 Medium-term		.filter2-2.filter3-1
    $('#right-target3[data-active=".filter2-2.filter3-1"]').removeClass("active").addClass("not-active");


    //	 Financially material priority	 Long-term		.filter2-3.filter3-1

    //	 Impact material priority	 Short-term		.filter2-1.filter3-2
    $('#right-target3[data-active=".filter2-1.filter3-2"]').removeClass("active").addClass("not-active");
    //	 Impact material priority	 Medium-term		.filter2-2.filter3-2
    $('#middle-target5[data-active=".filter2-2.filter3-2"],#path35[data-active=".filter2-2.filter3-2"],#right-target15[data-active=".filter2-2.filter3-2"]').removeClass("active").addClass("not-active");
    //	 Impact material priority	 Long-term		.filter2-3.filter3-2
    $('#left-target9[data-active=".filter2-3.filter3-2"],#middle-target5[data-active=".filter2-3.filter3-2"],#path35[data-active=".filter2-3.filter3-2"],#right-target15[data-active=".filter2-3.filter3-2"]').removeClass("active").addClass("not-active");
    //	 Upstream	 Short-term		.filter1-1.filter2-1
    $('#left-target2[data-active=".filter1-1.filter2-1"],#path3[data-active=".filter1-1.filter2-1"],#middle-target1[data-active=".filter1-1.filter2-1"]').removeClass("active").addClass("not-active");
    //	 Upstream	 Medium-term		.filter1-1.filter2-2
    //	 Upstream	 Long-term		.filter1-1.filter2-3
    $('#left-target9[data-active=".filter1-1.filter2-3"]').removeClass("active").addClass("not-active");
    //	 Own operations	 Short-term		.filter1-2.filter2-1
    $('#left-target9[data-active=".filter1-2.filter2-1"],#right-target3[data-active=".filter1-2.filter2-1"]').removeClass("active").addClass("not-active");
    //	 Own operations	 Medium-term		.filter1-2.filter2-2
    //	 Own operations	 Long-term		.filter1-2.filter2-3
    //	 Downstream	 Short-term		.filter1-2.filter2-1
    //	 Downstream	 Medium-term		.filter1-3.filter2-2
    $('#left-target1[data-active=".filter1-3.filter2-2"],#left-target2[data-active=".filter1-3.filter2-2"],#left-target3[data-active=".filter1-3.filter2-2"],#middle-target1[data-active=".filter1-3.filter2-2"],#middle-target3[data-active=".filter1-3.filter2-2"],#path23[data-active=".filter1-3.filter2-2"],#middle-target5[data-active=".filter1-3.filter2-2"],#right-target3[data-active=".filter1-3.filter2-2"],#right-target5[data-active=".filter1-3.filter2-2"],#right-target10[data-active=".filter1-3.filter2-2"],#path35[data-active=".filter1-3.filter2-2"],#right-target15[data-active=".filter1-3.filter2-2"]').removeClass("active").addClass("not-active");

    //	 Downstream	 Long-term		.filter1-3.filter2-3
    $('#left-target1[data-active=".filter1-3.filter2-3"],#left-target9[data-active=".filter1-3.filter2-3"],#middle-target1[data-active=".filter1-3.filter2-3"],#middle-target5[data-active=".filter1-3.filter2-3"],#right-target5[data-active=".filter1-3.filter2-3"],#path35[data-active=".filter1-3.filter2-3"],#right-target15[data-active=".filter1-3.filter2-3"]').removeClass("active").addClass("not-active");
    //	 Financially material priority	 Upstream	 Short-term	.filter1-1.filter2-1.filter3-1
    $('#left-target2[data-active=".filter1-1.filter2-1.filter3-1"],#left-target9[data-active=".filter1-1.filter2-1.filter3-1"],#path3[data-active=".filter1-1.filter2-1.filter3-1"],#middle-target1[data-active=".filter1-1.filter2-1.filter3-1"],#middle-target6[data-active=".filter1-1.filter2-1.filter3-1"]').removeClass("active").addClass("not-active");
    //	 Financially material priority	 Upstream	 Medium-term	.filter1-1.filter2-2.filter3-1
    //	 Financially material priority	 Upstream	 Long-term	.filter1-1.filter2-3.filter3-1
    $('#left-target9[data-active=".filter1-1.filter2-3.filter3-1"]').removeClass("active").addClass("not-active");
    //	 Financially material priority	 Own operations	 Short-term	.filter1-2.filter2-1.filter3-1
    $('#left-target9[data-active=".filter1-2.filter2-1.filter3-1"],#right-target3[data-active=".filter1-2.filter2-1.filter3-1"]').removeClass("active").addClass("not-active");
    //	 Financially material priority	 Own operations	 Medium-term	.filter1-2.filter2-2.filter3-1
    $('#right-target3[data-active=".filter1-2.filter2-2.filter3-1"]').removeClass("active").addClass("not-active");
    //	 Financially material priority	 Own operations	 Long-term	.filter1-2.filter2-3.filter3-1
    //	 Financially material priority	 Downstream	 Short-term	.filter1-3.filter2-1.filter3-1
    $('#left-target9[data-active=".filter1-3.filter2-1.filter3-1"]').removeClass("active").addClass("not-active");
    //	 Financially material priority	 Downstream	 Medium-term	.filter1-3.filter2-2.filter3-1
    $('#left-target1[data-active=".filter1-3.filter2-2.filter3-1"],#left-target2[data-active=".filter1-3.filter2-2.filter3-1"],#left-target3[data-active=".filter1-3.filter2-2.filter3-1"],#middle-target1[data-active=".filter1-3.filter2-2.filter3-1"],#middle-target3[data-active=".filter1-3.filter2-2.filter3-1"],#middle-target5[data-active=".filter1-3.filter2-2.filter3-1"],#right-target3[data-active=".filter1-3.filter2-2.filter3-1"],#right-target5[data-active=".filter1-3.filter2-2.filter3-1"],#path23[data-active=".filter1-3.filter2-2.filter3-1"],#right-target10[data-active=".filter1-3.filter2-2.filter3-1"],#path35[data-active=".filter1-3.filter2-2.filter3-1"],#right-target15[data-active=".filter1-3.filter2-2.filter3-1"]').removeClass("active").addClass("not-active");
    //	 Financially material priority	 Downstream	 Long-term	.filter1-3.filter2-3.filter3-1
    $('#left-target1[data-active=".filter1-3.filter2-3.filter3-1"],#left-target9[data-active=".filter1-3.filter2-3.filter3-1"],#middle-target1[data-active=".filter1-3.filter2-3.filter3-1"],#middle-target5[data-active=".filter1-3.filter2-3.filter3-1"],#right-target5[data-active=".filter1-3.filter2-3.filter3-1"],#path35[data-active=".filter1-3.filter2-3.filter3-1"],#right-target15[data-active=".filter1-3.filter2-3.filter3-1"]').removeClass("active").addClass("not-active");
    //	 Impact material priority	 Upstream	 Short-term	.filter1-1.filter2-1.filter3-2
    $('#left-target2[data-active=".filter1-1.filter2-1.filter3-2"],#path3[data-active=".filter1-1.filter2-1.filter3-2"],#middle-target1[data-active=".filter1-1.filter2-1.filter3-2"],#middle-target3[data-active=".filter1-1.filter2-1.filter3-2"]').removeClass("active").addClass("not-active");
    //	 Impact material priority	 Upstream	 Medium-term	.filter1-1.filter2-2.filter3-2
    $('#left-target2[data-active=".filter1-1.filter2-2.filter3-2"],#path3[data-active=".filter1-1.filter2-2.filter3-2"],#middle-target1[data-active=".filter1-1.filter2-2.filter3-2"],#middle-target3[data-active=".filter1-1.filter2-2.filter3-2"]').removeClass("active").addClass("not-active");
    //	 Impact material priority	 Upstream	 Long-term	.filter1-1.filter2-3.filter3-2
    $('#left-target2[data-active=".filter1-1.filter2-3.filter3-2"],#left-target9[data-active=".filter1-1.filter2-3.filter3-2"],#path3[data-active=".filter1-1.filter2-3.filter3-2"],#middle-target1[data-active=".filter1-1.filter2-3.filter3-2"],#middle-target3[data-active=".filter1-1.filter2-3.filter3-2"]').removeClass("active").addClass("not-active");
    //	 Impact material priority	 Own operations	 Short-term	.filter1-2.filter2-1.filter3-2
    $('#left-target4[data-active=".filter1-2.filter2-1.filter3-2"],#left-target9[data-active=".filter1-2.filter2-1.filter3-2"],#right-target3[data-active=".filter1-2.filter2-1.filter3-2"]').removeClass("active").addClass("not-active");
    //	 Impact material priority	 Own operations	 Medium-term	.filter1-2.filter2-2.filter3-2
    $('#middle-target5[data-active=".filter1-2.filter2-2.filter3-2"],#path35[data-active=".filter1-2.filter2-2.filter3-2"],#right-target15[data-active=".filter1-2.filter2-2.filter3-2"]').removeClass("active").addClass("not-active");
    //	 Impact material priority	 Own operations	 Long-term	.filter1-2.filter2-3.filter3-2
    $('#left-target9[data-active=".filter1-2.filter2-3.filter3-2"],#middle-target5[data-active=".filter1-2.filter2-3.filter3-2"],#path35[data-active=".filter1-2.filter2-3.filter3-2"],#right-target15[data-active=".filter1-2.filter2-3.filter3-2"]').removeClass("active").addClass("not-active");
    //	 Impact material priority	 Downstream	 Short-term	.filter1-3.filter2-1.filter3-2
    $('#middle-target1[data-active=".filter1-3.filter2-1.filter3-2"],#right-target3[data-active=".filter1-3.filter2-1.filter3-2"]').removeClass("active").addClass("not-active");
    //	 Impact material priority	 Downstream	 Medium-term	.filter1-3.filter2-2.filter3-2
    $('#left-target2[data-active=".filter1-3.filter2-2.filter3-2"],#left-target3[data-active=".filter1-3.filter2-2.filter3-2"],#middle-target1[data-active=".filter1-3.filter2-2.filter3-2"],#middle-target3[data-active=".filter1-3.filter2-2.filter3-2"],#middle-target5[data-active=".filter1-3.filter2-2.filter3-2"],#right-target3[data-active=".filter1-3.filter2-2.filter3-2"],#path23[data-active=".filter1-3.filter2-2.filter3-2"],#right-target10[data-active=".filter1-3.filter2-2.filter3-2"],#path35[data-active=".filter1-3.filter2-2.filter3-2"],#right-target15[data-active=".filter1-3.filter2-2.filter3-2"]').removeClass("active").addClass("not-active");
    //	 Impact material priority	 Downstream	 Long-term	.filter1-3.filter2-3.filter3-2
    $('#left-target9[data-active=".filter1-3.filter2-3.filter3-2"],#middle-target1[data-active=".filter1-3.filter2-3.filter3-2"],#middle-target5[data-active=".filter1-3.filter2-3.filter3-2"],#path35[data-active=".filter1-3.filter2-3.filter3-2"],#right-target15[data-active=".filter1-3.filter2-3.filter3-2"]').removeClass("active").addClass("not-active");


     if($('.lines-box:visible').hasClass('vis-mob')){
        $('.tabs-materiality-assessment .lines-box:visible path.not-active').prependTo($('.tabs-materiality-assessment .lines-box:visible svg #g448'));
    }else{
        $('.tabs-materiality-assessment .lines-box:visible path.not-active').prependTo($('.tabs-materiality-assessment .lines-box:visible svg'));
    }
     }
})
});
});
jQuery(document).ready(function($) {
    $('.left-nav li a').on('click', function(e){
        e.preventDefault();
        if(jQuery(this).attr('href')){
            jQuery('body, html').stop(true, true).animate({
                scrollTop: jQuery('' + jQuery(this).attr('href')).offset().top
            }, 500);
        }
    });
    $('.toggle-tabs-wrap a').click( function (e){ console.log($(this).attr('href'));
        e.preventDefault();
        $(this).addClass('active').siblings('a').removeClass('active');
        $('#'+$(this).attr('href')).addClass('active').siblings('.tab-item').removeClass('active');
        $('div[data-toggle]').removeClass('active');
        $('[data-toggle='+$(this).attr('href')+']').addClass('active');
    });
    $('.esg-performance-section .toggle-tabs li').hover(function (e) {
        //e.preventDefault();
        $(this).addClass('active').siblings('li').removeClass('active');
        $($(this).find('a').attr('data-target')).addClass('active').siblings('.item-tab').removeClass('active');
    },function () {

    })
    $('.map-wrap .toggle-el').click(function () {
        $('path,.outer-text,#center-text').addClass('not-active').removeClass('active').attr('data-active','');
        $('circle,.outer-text').addClass('not-active').removeClass('active').attr('data-active','');
        $('g').removeClass('active').attr('data-active','');
        $('#ellipse-center').attr('class','');
        if($(this).hasClass('outer-text')){
            var elTarget=$(this).attr('data-toggle');
        }else{
            var elTarget=$(this).attr('id');
        }
        $('#ellipse-center').addClass(elTarget);
        $('.content-panel').removeClass('active');
        $('.'+elTarget).attr('data-active',elTarget);
        $('.'+elTarget).addClass('active').removeClass('not-active');
        $('.center-content p').remove();
        $('.center-content').addClass('active');
        $('.reset-map').addClass('active');
        var arrNum=[];
        $('.circle-in-m.active').each(function () {
             arrNum.push($(this).attr('data-num'));
        });
        arrNum=arrNum.sort();
        //console.log(arrNum);
        arrNum.forEach(function(item, i, arr) {
            var textH='';
            //console.log( i + ": " + item + " (массив:" + arr + ")" );
            if($('.content-panel[data-num='+item+']').find('.chin').length){
                textH=$('.content-panel[data-num='+item+']').find('.chin').html();
            }else{
                textH=$('.content-panel[data-num='+item+']').find('h2').text();
            }

            $('.center-content').append('<p><i>'+$('.content-panel[data-num='+item+']').find('.icon').html()+'</i>'+textH+'</p>')
        });
    });
    $('.map-wrap .reset-map').click(function (e) {
        e.preventDefault();
        $('path,.outer-text,#center-text').removeClass('not-active').removeClass('active').attr('data-active','');
        $('circle,.outer-text').removeClass('not-active').removeClass('active').attr('data-active','');
        $('g').removeClass('active').attr('data-active','');
        $('#ellipse-center').attr('class','');
        $('.content-panel').removeClass('active');
        $('.content-panel:first-child').addClass('active');
        $('.center-content p').remove();
        $('.center-content').removeClass('active');
        $(this).removeClass('active');
    });

    $('.toggle-right .toggle-materiality-assessment').click(function (e) {
        e.preventDefault();
        $('.indicator').removeClass('not-active').addClass('active');
    })
    $('.toggle-middle .toggle-materiality-assessment').click(function (e) {
        e.preventDefault();
        $('.indicator').removeClass('active').addClass('not-active');
    })
    $('.toggle-left .toggle-materiality-assessment').click(function (e) {
        e.preventDefault();
        $('.indicator').removeClass('active').addClass('not-active');
    })


    $('.materiality-assessment-wrap-map .toggle-materiality-assessment').click(function (e) {
        e.preventDefault();
        $('.radio-filter input:radio').each(function () { $(this).prop('checked', false); });
        $('path,.toggle-materiality-assessment').addClass('not-active').removeClass('active').removeClass('section_active').attr('data-active', '');
        $('.item-tabs-content-materiality').removeClass('active');
        $('.materiality-assessment-wrap-map').removeClass('filtered');
        let elTarget=$(this).attr('id');
        $('.'+elTarget).attr('data-active',elTarget);
        $('.'+elTarget).addClass('active').removeClass('not-active');
        //console.log($('.lines-box:visible').hasClass('vis-mob'));
        if($('.lines-box:visible').hasClass('vis-mob')){
            $('.tabs-materiality-assessment .lines-box:visible path.not-active').prependTo($('.tabs-materiality-assessment .lines-box:visible svg #g448'));
        }else{
            $('.tabs-materiality-assessment .lines-box:visible path.not-active').prependTo($('.tabs-materiality-assessment .lines-box:visible svg'));
        }

    })
    $('.materiality-assessment-wrap-map .link-toggle-map').click(function (e) {
        e.preventDefault();
        $('path,.toggle-materiality-assessment').addClass('not-active').removeClass('active').removeClass('section_active').attr('data-active', '');

        let elToggle = $(this).attr('data-toggle').split(' ');
            //console.log(elToggle);
        for (let i = 0; i < elToggle.length; i++) {
            //console.log(elToggle[i]);
            $('.'+elToggle[i]).attr('data-active',elToggle[i]);
            $('.'+elToggle[i]).addClass('active').addClass('section_active').removeClass('not-active');
            if($('.lines-box:visible').hasClass('vis-mob')){
                $('.tabs-materiality-assessment .lines-box:visible path.not-active').prependTo($('.tabs-materiality-assessment .lines-box:visible svg #g448'));
            }else{
                $('.tabs-materiality-assessment .lines-box:visible path.not-active').prependTo($('.tabs-materiality-assessment .lines-box:visible svg'));
            }
        }
       // $('.toggle-middle '+elToggle).trigger('click');

    })
    $('.materiality-assessment-wrap-map .reset-map-assessment').click(function (e) {
        e.preventDefault();
        $('.radio-filter input:radio').each(function () { $(this).prop('checked', false); });
        $('.item-tabs-content-materiality').removeClass('active');
        $('.materiality-assessment-wrap-map').removeClass('filtered');
        $('.indicator').removeClass('active').addClass('not-active');
        $('.item-tabs-content-materiality:first-child').addClass('active');
        $('path,.toggle-materiality-assessment').removeClass('not-active').removeClass('section_active').removeClass('active').attr('data-active', '');
        
    })
    //$('.main-menu').clone().insertAfter('.mob-menu .search');
    /* $('.item-accordion .toggle-accord').click( function (e){
        if(!$(this).parent('.item-accordion').hasClass('open-def')){
            $(this).parent('.item-accordion').toggleClass('open');
            $(this).siblings('.content-accord').stop().slideToggle(300);
        }else{
            var ths=$(this);
            $(this).siblings('.content-accord').stop().slideUp(300,function () {
                ths.parent('.item-accordion').removeClass('open-def');
            });
        }
    });
    $('.toggle-menu-scroll').click( function (e){
        $(this).toggleClass('active');
        $('.scroll-links .container ul').stop().slideToggle(300);
    });




    $('.next-scr').on('click', function(e){
        e.preventDefault();
        if(jQuery(this).attr('href')){
            jQuery('body, html').stop(true, true).animate({
                scrollTop: jQuery('' + jQuery(this).attr('href')).offset().top
            }, 500);
        }
    });
    $('.toggle-block .item-toggle').click( function (e){
        e.preventDefault();
        $(this).toggleClass('active').siblings().removeClass('active');
        $('.box-text .wrap-text-more>div').eq($(this).index()).stop().slideToggle(300).siblings().slideUp(300)
    });
     var posTop =jQuery('.scroll-links').offset().top;


    $('.parallax .background').parallax('50%', 0.7, true);*/
    $('.open-popup-vid').magnificPopup({
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false
    });
    $('.dropdown-style .wrap-drop-down .toggle-open').on('click', function(e){
        $('.dropdown-style .wrap-drop-down').toggleClass('open')
    });
    $('.nav-second ul li a').on('click', function(e){
        e.preventDefault();
        if(jQuery(this).attr('href')){
            jQuery('body, html').stop(true, true).animate({
                scrollTop: jQuery('' + jQuery(this).attr('href')).offset().top-$('.nav-second').outerHeight()
            }, 500);
        }
        $('.nav-second.dropdown-style .wrap-drop-down').removeClass('open')
    });
    $('.back-top-scroll').click( function (e){
        e.preventDefault();
        jQuery('body, html').stop(true, true).animate({
            scrollTop: 0
        }, 500);
    });
    $('.accordion .btn-close a').click(function (e) {
        e.preventDefault();
        var ths = $(this);
        $(this).closest('.panel').find('.panel-title > a').trigger('click');
       /* setTimeout(function () {
            jQuery('body, html').stop(true, true).animate({
                scrollTop: ths.parents('.panel').offset().top
            }, 500);
        },300)*/
    });
    $('.filter ul li input').change(function () {
        var cat=[];
        var years=[];
        if($(this).val()!='all'){
            $(this).parents('ul').find('li:first-child input').prop('checked', false);
        }
        if($(this).val()=='all'){
            $(this).parents('ul').find('li+li input').prop('checked', false);
        }
        $( "#category input:checked" ).each(function () {
            cat.push($(this).val());
        });
        $( "#year input:checked" ).each(function () {
            years.push($(this).val());
        });
        if(years.length<1){
            years.push('all');
        }
        if(cat.length<1){
            cat.push('all');
        }
        console.log(cat);
        console.log(years);
        var arrContetn;
        if(cat[0]!='all' && years[0]=='all'){
            arrContetn='catYes';
        }
        if(cat[0]=='all' && years[0]!='all'){
            arrContetn='yearYes';
        }
        if(cat[0]!='all' && years[0]!='all'){
            arrContetn='catBoth';

        }
        console.log(arrContetn);
        $('.wrap-studies').css('display','none');
        $('.item-study').css('display','none');

        $('.item-study').each(function () {
            switch(arrContetn) {
                case 'catBoth':  // if (x === 'value1')
                    if(cat.indexOf($(this).attr('data-cat')) != -1 && years.indexOf($(this).attr('data-year')) != -1){
                        $(this).css('display','block');
                    }
                    break;

                case 'yearYes':  // if (x === 'value2')
                    if(years.indexOf($(this).attr('data-year')) != -1){
                        $(this).css('display','block');
                    }
                    break;
                case 'catYes':  // if (x === 'value2')
                    if(cat.indexOf($(this).attr('data-cat')) != -1){
                        $(this).css('display','block');
                    }
                    break;
                default:
                    $('.item-study').css('display','block');

            }


        });
        $('.wrap-studies').fadeIn(500);
    });
    $('.filter .reset').click(function (e) {
        e.preventDefault();
        $('.filter ul li:first-child input').trigger('click');
        $('.filter ul li:first-child input').prop('checked', true);
    });
    $('.filternew .wrap-filter-part h5').click(function (e) {
        e.preventDefault();

        if(window.innerWidth<992){
            $(this).siblings('ul').stop().slideToggle(300);
        }


    });
   /* var timeOutS;
    var hoverTs = false;
    $('.search-f-w').hover(function () {
        $('body').addClass('active-sub-menu');
        $(this).addClass('active');
        $('.search-submenu').addClass('active');
    }, function (e) {
        hoverTs = false;
        timeOutS=setTimeout(function () {

            if(!hoverTs){
                $('.search-f-w').removeClass('active');
                $('.search-submenu').removeClass('active');
                $('body').removeClass('active-sub-menu');
            }


        }, 600 );
    });
    $('.search-submenu').mouseover(function () {
        hoverTs = true;
        clearTimeout(timeOutS);
    });
    $('.search-submenu').mouseleave(function () {
        hoverTs = false;
        $(this).removeClass('active');
        $('.search-f-w').removeClass('active');
        $('body').removeClass('active-sub-menu');
    });
    var hoverT = false;
    var timeOut;
    var transit =false;
    var leaveSub = false;
    $('.wrap-nav .icons a').hover(function () {
        hoverT = true;
        //console.log(leaveSub);
        clearTimeout(timeOut);
        //$('.sub-menu').removeClass('active');
        //$('body').removeClass('active-sub-menu');
        $('.main-header-n .main-menu li').addClass('off');
        $('.icons').removeClass('off');

    }, function (e) {
        hoverT = false;

        timeOut=setTimeout(function () {


            //$('.sub-menu').removeClass('active');
            //$('body').removeClass('active-sub-menu');
            $('.main-header-n .main-menu li, .icons').removeClass('off');
            //transit = false;
            //leaveSub = false;
            $('.sub-menu').css('transition','');
            if($('body').hasClass('active-sub-menu')){
                $('.main-header-n .main-menu li, .icons').addClass('off');
                $('.main-header-n .main-menu li.open').removeClass('off');
            }


        }, 200 );
    });
    $('.main-header-n .menu-second li a,.main-header-n .search-f-w,.main-header-n .logo, .main-block').hover(function (e) {
        $('.sub-menu').removeClass('active');
        $('body').removeClass('active-sub-menu');
        $('.main-header-n .main-menu  li').removeClass('open');
    },function () {

    });
    $('.main-header-n .main-menu li a').hover(function (e) {
        e.preventDefault();
        $('.sub-menu').removeClass('active');
        $('body').removeClass('active-sub-menu');
        $('.main-header-n .main-menu li').removeClass('open');
        $(this).parent('li').addClass('open');
        //$('main-header-n .wrap-nav ul li, .icons').removeClass('off');
        $('.sub-menu').css('transition','');
        var curTarget = $(this).text().toLowerCase();

        if($('[data-target="'+curTarget+'"]').length){
            if(transit){
                $('.sub-menu').css('transition','none');
            }

            transit = true;
            leaveSub = false;
            $('[data-target="'+curTarget+'"]').addClass('active');
            $('body').addClass('active-sub-menu');

        }
    },function () {

    });*/
    $(document).on('click',function (e) {
        if($(e.target).closest('.sub-menu ul li a').length){
            return
        }else{
            $('.main-header-n .main-menu li, .icons').removeClass('off');
            $('.sub-menu').removeClass('active');
            $('body').removeClass('active-sub-menu');
            transit = false;
            leaveSub = false;
        }
    });
    $('.sub-menu .close-menu').click(function () {
        $('.main-header-n .main-menu li, .icons').removeClass('off');
        $('.sub-menu').removeClass('active');
        $('body').removeClass('active-sub-menu');
        transit = false;
        leaveSub = false;
    });
    $('.main-header-n .main-menu li a').hover(function () {
        hoverT = true;
        //console.log(leaveSub);
        clearTimeout(timeOut);
        //$('.sub-menu').removeClass('active');
        //$('body').removeClass('active-sub-menu');
        $('.main-header-n .main-menu li, .icons').removeClass('off');
        $(this).parent('li').siblings('li').addClass('off');
        $('.icons').addClass('off');


    }, function (e) {
        hoverT = false;

        timeOut=setTimeout(function () {

            //if(!$('body').hasClass('active-sub-menu') && !$(this).parent('li').hasClass('has-submenu')){
            //$('.sub-menu').removeClass('active');
            //$('body').removeClass('active-sub-menu');
            $('.main-header-n .main-menu li, .icons').removeClass('off');
            //transit = false;
            //leaveSub = false;
            $('.sub-menu').css('transition','');
            // }
            if($('body').hasClass('active-sub-menu')){
                $('.main-header-n .main-menu li, .icons').addClass('off');
                $('.main-header-n .main-menu li.open').removeClass('off');
            }


        }, 200 );
    });
    $('.link-title').click(function (e) {
        e.preventDefault();
        $('.copy-link').removeClass('open');
        $(this).find('.copy-link').addClass('open');
        var getRemove = window.location.href.split('&');
        var hash1 = getRemove[0].split('#');
        console.log(hash1);
        if($(this).parent('h2').attr('id')!=undefined){
            var idhesh =$(this).parent('h2').attr('id');
        }else{
            var idhesh =$(this).parents('.scroll-sect').attr('id');
        }
        var copyLink = hash1[0]+'#'+idhesh;
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val(copyLink).select();
        document.execCommand("copy");
        $temp.remove();
    });
    $('.ownreport__inside input[type=checkbox]').change(function () {
        var count=$('.ownreport__inside input[type=checkbox]:checked').length;
        $('#checkboxCount').text(count);
    });
    $('.clear_section.btnborder').click(function () {
        $('.ownreport__inside input[type=checkbox]').prop('checked',false);
        $('#checkboxCount').text('0');
    });
    $('.mob-menu li').has('ul').addClass('has-child').append("<i class='la'></i>");
    $('.back-mob-m').click(function (e) {
        e.preventDefault();
        if($('.wrap-list').hasClass('left-end') && $('.wrap-list').hasClass('left-full')){
            $('.wrap-list').removeClass('left-full');

        }
        else if($('.wrap-list').hasClass('left-end') && !$('.wrap-list').hasClass('left-full')){
            $('.wrap-list').removeClass('left-end');

        }else{
            $('.wrap-list').removeClass('left-m');
            $('.wrap-list>ul').css('height','');
        }
    })
    $(document).on('click','.wrap-list>ul>.has-child>.la', function(e){
        e.preventDefault();
        $('.wrap-list>ul>.has-child').removeClass('active');
        $(this).parent('li').addClass('active');
        $('.wrap-list').addClass('left-m');
        $('.wrap-list>ul').height($(this).siblings('ul').height());

    });
    $(document).on('click','.wrap-list>ul ul .la', function(e){
        e.preventDefault();
        if($('.wrap-list').hasClass('left-end')){
            $('.wrap-list>ul>li>ul>li ul .has-child').removeClass('active');
            $('.wrap-list').addClass('left-full');

        }else{
            $('.wrap-list>ul>li>ul>li.has-child').removeClass('active');
        }

        $(this).parent('li').addClass('active')
        $('.wrap-list>ul').height($(this).siblings('ul').height());
        $('.wrap-list').addClass('left-end');

    });
    $('.main-header-n .toggle-menu').not('.main-nav-menu').on('click', function(e){
        e.preventDefault();
        $(this).toggleClass('open');
        $('.mob-menu').stop().slideToggle(300);

    });
    $('.main-header-n .toggle-menu.main-nav-menu').on('click', function(e){
        e.preventDefault();
        $(this).toggleClass('open');
        $('.sub-menu').stop().slideToggle(300);

    });
    var horScroll;
    if ($('.nav-second').length) {
        var posTop = $('.nav-second').offset().top;
        /* horScroll = new IScroll(
            '.nav-second .container',
            {
                scrollX: true,
                scrollY: false,
                mouseWheel: false,
                interactiveScrollbars: false,
                scrollbars: false,
                bounce: false,
                click: true,
                probeType: 3
            }
        );

        horScroll.on('scroll', function () {
            if((this.x>>0)<=-10){
                $('.nav-second .container').addClass('left0')
            }else{
                $('.nav-second .container').removeClass('left0')
            }
            if($('.nav-second .container ul').outerWidth()+(this.x>>0)<=$(window).width()+40){
                $('.nav-second .container').addClass('right0')
            }else{
                $('.nav-second .container').removeClass('right0')
            }
        });*/
    }

    $(window).resize(function () {
        //jQuery('.ceo-message-section,.content-home-ban').each(function () {
        //    jQuery(this).css('min-height','');
        //    jQuery(this).css('min-height',jQuery(this).find('.container').height()+(jQuery(this).outerHeight()-jQuery(this).find('.container').height()));
        //});
    });
    $(window).scroll(function () {
        if($(this).scrollTop()>400){
            $('.back-top').addClass('on');
        }else{
             $('.back-top').removeClass('on');
        }
        if($(this).scrollTop()>=posTop){
            $('.nav-second').addClass('fixed');
            $('.wrap-nav').css('padding-top',$('.nav-second').outerHeight());
        }else{
            $('.nav-second').removeClass('fixed');
            $('.wrap-nav').css('padding-top','');
        }
       if ($('.nav-second').length) {
            $('.nav-second ul li').each(function (index) {
                if ($(this).hasClass('active')) {
                    var a = $('.nav-second ul li').eq($(this).index() - 1);

                    if (($(this).offset().left + $(this).outerWidth()) >= jQuery(window).width() || ($(this).offset().left) < 0) {
                         if (($('.nav-second ul').width() - $(this).position().left) > jQuery(window).width()) {
                            horScroll.scrollTo(-($(this).position().left - 12), 0, 500);
                        } else {
                            horScroll.scrollTo(jQuery(window).width() - $('.nav-second ul').width(), 0, 500);
                        }

                    }

                }
            });
        }
        posPage();

    });
    if($('.video-full .start-video').length > 0 && $('.start-video').attr('href').search('youtube') != -1){
        function YouTubeGetID(url){
            var ID = '';
            url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
            if(url[2] !== undefined) {
                ID = url[2].split(/[^0-9a-z_\-]/i);
                ID = ID[0];
            }
            else {
                ID = url;
            }
            return ID;
        }
        var tag = document.createElement('script');
        tag.src = "//www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        var player;
        var player2;
        var player3;
        var player4;
        var idVid = document.getElementById ("start-video");
        var idVid2 = document.getElementById ("start-video2");
        var idVid3 = document.getElementById ("start-video3");
        var idVid4 = document.getElementById ("start-video4");
        onYouTubeIframeAPIReady = function () {
            player = new YT.Player('player', {
                height: '100%',
                width: '100%',
                videoId: YouTubeGetID( $(idVid).attr('href')),  // youtube video id
                playerVars: {
                    'autoplay': 0,
                    'rel': 0,
                    'showinfo': 0
                },
                events: {
                    'onStateChange': onPlayerStateChange
                }
            });

        };
        var p = document.getElementById ("player");
        $(p).hide();
        var t = document.getElementById ("thumbnail");
        t.src = $(t).attr('src');
        onPlayerStateChange = function (event) {
            if (event.data == YT.PlayerState.ENDED) {
                $('.start-video').fadeIn('normal');
            }
        };

        $(document).on('click', '.start-video', function (e) {
            e.preventDefault();
            $(this).hide();
            $(this).parents('.video-full').addClass('off');
            if($(this).attr('id')=='start-video2'){
                $("#player2").show();
                $("#thumbnail_container2").hide();
                player2.playVideo();
            }
            else if($(this).attr('id')=='start-video3'){
                $("#player3").show();
                $("#thumbnail_container3").hide();
                player3.playVideo();
            }
            else if($(this).attr('id')=='start-video4'){
                $("#player4").show();
                $("#thumbnail_container4").hide();
                player4.playVideo();
            }
            else{
                $("#player").show();
                $("#thumbnail_container").hide();
                player.playVideo();
            }


        });
    }
    $('.cmp-cookies .cmp-cookies__action-link-ok').click(function (e) {
        e.preventDefault();
        $.cookie('popup', 'seen', { expires: 100, path: '/' });
        $('.cmp-cookies').removeClass('on')
    })
    if($.cookie('popup') != 'seen' && $('.cmp-cookies').length>0){
        $('.cmp-cookies').addClass('on')

    };
});

jQuery(window).on('load', function () {

    $("#open-1").click(function(){
        $(".award-1 .item-sl").css("display","inline-block");
        $(this).hide();
          $("#close-1").show();
          $(".award-1 .arrow-btn").addClass("close")
      });
      $("#close-1").click(function(){
        $(".award-1 .item-sl").css("display","none");
            $(this).hide();
          $("#open-1").show();
          $(".award-1 .arrow-btn").removeClass("close")
      });
      $("#open-2").click(function(){
        $(".award-2 .item-sl").css("display","inline-block");
        $(this).hide();
          $("#close-2").show();
          $(".award-2 .arrow-btn").addClass("close")
      });
      $("#close-2").click(function(){
        $(".award-2 .item-sl").css("display","none");
            $(this).hide();
          $("#open-2").show();
          $(".award-2 .arrow-btn").removeClass("close")
      });


   // jQuery('.ceo-message-section,.content-home-ban').each(function () {
    //    jQuery(this).css('min-height',jQuery(this).find('.container').height()+160);
    //});
    jQuery('.slider-study').slick({
        slidesToShow: 1,
        infinite: true,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 1000,
        dots: true,
        arrows:true,
        slide:'.item-sl',
        appendDots:jQuery('.nav-slider'),
        appendArrows:jQuery('.nav-slider')
    });
    jQuery('.home-slider').slick({
        slidesToShow: 1,
        infinite: true,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        speed: 1000,
        dots: true,
      arrows: true,
      appendDots: $(".slide-m-dots"),
      prevArrow: $(".slide-m-prev"),
      nextArrow: $(".slide-m-next")
    });
    jQuery('.slider-logos').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 1000,
        dots: false,
        arrows:true,
        responsive: [


            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint:450,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }

        ]
    });
    //$('.sub-menu .scroll .right-p > ul').height($(window).height()-173);
    //$(".scroll-m3").mCustomScrollbar({
    //  theme:"dark-thin"
    //});
    var count =0;
    var arrClass =[];
    $('.scroll-m').each(function () {
        count++;
        $(this).addClass('selector-m'+count);
        arrClass.push('selector-m'+count);
    });
    arrClass.forEach(function(item, i, arr) {
        item = new IScroll(
            '.'+item,
            {
                mouseWheel: true,
                interactiveScrollbars: true,
                scrollbars: true,
                click: true
            }
        );
    });
    //posPage();
    //jQuery('.shadow-block').css('height',jQuery('.slider').height())
    /*jQuery('.slider-funds').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 1000,
        dots: false,
        arrows:true,
        responsive: [


            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    variableWidth: true
                }
            },
            {
                breakpoint:550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }

        ]

    });
    $('.white-popup .btn').click(function () {
        $.cookie('popup', 'seen', { expires: 100, path: '/' });
    })
    if($.cookie('popup') != 'seen' && $('.white-popup').length>0){


        setTimeout(function () {

            $.magnificPopup.open({
                items: {
                    src: '.white-popup'
                },
                //removalDelay: 500, //delay removal by X to allow out-animation
                callbacks: {
                    beforeOpen: function() {
                        this.st.mainClass = 'mfp-move-from-top';
                    }
                },
                closeOnBgClick: false,
                midClick: false // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
            });console.log($.cookie('popup'));
        }, 800);
    };*/
});